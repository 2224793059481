import i18n from '@/i18n/index'
import constant from '@/utils/constant.js'
export default {
  formData: [{
    title: '',
    name: 0,
    createDateItem: [{
      span: 8,
      prop: 'rest_id',
      name: 'select',
      options: [],
      label: i18n.t('form.rest.restList'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        key: 'id',
        value: 'id',
        nextChange: true,
        nextChangeProp: 'rest_id',
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.rest.restList') }) }
        ]
      }
    }, {
      span: 8,
      prop: 'rest_type_id',
      name: 'select',
      options: [],
      label: i18n.t('menu.roomList'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        key: 'id',
        value: 'id',
        disabled: true,
        nextChange: true,
        nextChangeProp: 'rest_type_id',
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('menu.roomList') }) }
        ]
      }
    }, {
      span: 8,
      prop: 'name',
      name: 'input',
      label: i18n.t('form.product.cnName'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.product.cnName') }) }
        ]
      }
    }]
  }]
}
