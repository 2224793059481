<template>
  <alter-form
    labelWidth="150px"
    :watchImageVisible="true"
    :collapseLength="collapseLength"
    :modifyData="modifyData"
    @formSubmit="formSubmit"
    @handleChange="handleChange"
    :formData="formData"
  />
</template>

<script>
// 组件
import alterForm from '@/components/alterForm'
import {
  createRestRoom, 
  getRestList,
  getRestTypeList,
  getRestRoomInfoPreview,
  updateRestRoomInfo
} from '@/services/rests';

import constant from '@/utils/constant.js'
import jsonData from './jsonData'
import utils from '@/utils/Utils'
import loading from '@/utils/loading.json'
import _ from 'lodash'
export default {
  components: {
    alterForm
  },
  data () {
    return {
      constant,
      formData: _.cloneDeep(jsonData.formData),
      modifyData: {},
      collapseLength: [],
      id: ''
    }
  },
  created () {
    this.id = this.$route.query.id || ''
    this.initData()
  },
  methods: {
    async initData () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      const res = await getRestList()
      const { arr } = utils.formatTableData(res.data.objects)
      this.formData[0].createDateItem[0].options = arr
      if (this.id) {
        const restRes = await getRestRoomInfoPreview(this.id)
        if (restRes.status == 200 && restRes.data) {
          let restInfo = restRes.data.rest_room
          if (restInfo.rest_type_id) {
            this.formData[0].createDateItem[1].Object.disabled = false
            await this.initRestType()
          }
          this.modifyData = { ...restInfo }
        }
      }
      this.$store.state.app.loading.close()
    },
    async initRestType () {
      this.formData[0].createDateItem[1].Object.loading = true
      const res = await getRestTypeList({page: 1, per_page: 1000})
      const { arr } = utils.formatTableData(res.data.objects)
      this.formData[0].createDateItem[1].options = arr
      this.formData[0].createDateItem[1].Object.loading = false
    },
    async handleChange(data, nextChangeProp) {
      if (nextChangeProp === 'rest_id') {
        this.formData[0].createDateItem[1].Object.disabled = false
        this.$set(this.modifyData, 'rest_type_id', '')
        this.restChange(data)
      }
    },
    async restChange (data) {
      this.formData[0].createDateItem[1].Object.loading = true
      const res = await getRestTypeList({rest_id_eq: data, page: 1, per_page: 1000})
      const { arr } = utils.formatTableData(res.data.objects)
      this.formData[0].createDateItem[1].options = arr
      this.formData[0].createDateItem[1].Object.loading = false
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      let api = () => createRestRoom(data)
      if (this.id) {
        api = () => updateRestRoomInfo(this.id, data)
      }
      api().then((res) => {
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            this.$router.push({
              name: 'restsManagerRoomList'
            })
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>